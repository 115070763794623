import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import { Crisp } from "crisp-sdk-web";

// Initialize analytics and monitoring only in production
if (process.env.REACT_APP_ENV === 'production') {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, 
    {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    }
  );

  // Capture app initialized event
  posthog.capture('app_initialized', {
    timestamp: new Date().toISOString()
  });

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [
      // Production URLs
      /^https:\/\/swaggyx\.com/,
      /^https:\/\/www\.swaggyx\.com/,
      // Staging URLs
      /^https:\/\/swaggyx\.debugged\.com\.my/,
      /^https:\/\/swaggybet-ivcn\.onrender\.com/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV
  });

  // Initialize Crisp
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = '8da3f055-9770-46c8-8ff3-c8b95287e57b';
  (function () {
    var d = document;
    var s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
  
  // Set up Crisp event listener for when chat is loaded
  window.$crisp.push(["on", "session:loaded", function() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.email) {
      window.$crisp.push(["set", "user:email", user.email]);
    }
  }]);
  
  // Set up Crisp event listener for when user logs in/out
  window.$crisp.push(["on", "user:logged-in", function() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.email) {
      window.$crisp.push(["set", "user:email", user.email]);
    }
  }]);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {process.env.REACT_APP_ENV === 'production' ? (
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    ) : (
      <App />
    )}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
