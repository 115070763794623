// NavBar.jsx
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../assets/images/swaggyxlogo.png";
import LoginForm from "./LoginForm";
import { useAuth } from "../AuthContext";
import { CoinsContext } from "../contexts/CoinsContext";
import {
  MagnifyingGlassIcon,
  UserCircleIcon,
  Bars3Icon,
  XMarkIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/solid";
import NotificationBell from "./User/NotificationBell";
import Pusher from "pusher-js";
import toast from "react-hot-toast";
import { useApi } from "../contexts/ApiContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  // State for managing the login modal
  //const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState("login");
  const { apiHost, apiVersion } = useApi();
  // User and authentication context
  const { user, isAuthenticated, logout, balance, toggleModal, showModal } = useAuth();
  const navigate = useNavigate();

  // State for search functionality
  const [searchTerm, setSearchTerm] = useState("");
  const { coinsData } = useContext(CoinsContext);

  // States for managing dropdowns and menus
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false); // New state for notifications

  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState('personal'); // Add this with other state declarations
  const { t, i18n } = useTranslation();

  // Prevent background scroll when mobile menu or notification panel is open
  useEffect(() => {
    if (mobileMenuOpen || notificationOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileMenuOpen, notificationOpen]);

  const userId = user?.id; // Replace with authenticated user ID
  const token = localStorage.getItem("sanctum_token");

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await fetch(`${apiHost}${apiVersion}/notifications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`${t('Error fetching notifications')}: ${response.statusText}`);
      }
      const data = await response.json();
      setNotifications(data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [apiHost, apiVersion, token]);

  useEffect(() => {
    if (userId && token) {
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      });
      var channel = pusher.subscribe(`user.${userId}`);
      channel.bind("live-notification", async function (data) {
        console.log(data);
        await fetchNotifications();
        toast.success(data.title);
      });
      fetchNotifications();
    }
    return () => {
      if (userId && token) {
        channel.unbind_all();
        pusher.unsubscribe(`user.${userId}`);
      }
    };
  }, [fetchNotifications, token, userId]);

  const handleNotificationRead = async (notificationId) => {
    const token = localStorage.getItem("sanctum_token");
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}/mark-notification-as-read/${notificationId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error(
          `${t('Error marking notification as read')}: ${response.statusText}`
        );
      }
      setNotificationOpen(false);
      await fetchNotifications();
      toast.success(t("Notification marked as read"));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    const token = localStorage.getItem("sanctum_token");
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}/mark-all-notifications-as-read`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error(
          `${t('Error marking all notifications as read')}: ${response.statusText}`
        );
      }
      setNotificationOpen(false);
      await fetchNotifications();
      toast.success(t("All notifications marked as read"));
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };
  // Filtered coin list based on search term
  const filteredCoins = coinsData.filter((coin) =>
    coin.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Functions for opening login or register modals
  //const toggleModal = () => setShowModal(!showModal);
  const openLoginForm = () => {
    setFormMode("login");
    toggleModal();
  };
  const openRegisterForm = () => {
    setFormMode("register");
    toggleModal();
  };

  // Navigate to specific coin event page
  const handleCoinClick = (coinName, id, type) => {
    navigate(`/event/${type.toLowerCase()}/${coinName.toLowerCase()}/${id}`);
    setSearchTerm(""); // Clear search input
  };

  // Function to navigate to dashboard when user icon/name is clicked
  const handleUserClick = () => navigate("/dashboard");

  // Function to navigate to the deposit page
  const handleDeposit = () => {
    navigate("/dashboard", { state: { shouldOpenDepositModal: true } });
  };

  // Coin icon mappings
  const iconMappings = {
    BTC: "https://assets.coingecko.com/coins/images/1/large/bitcoin.png",
    ETH: "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
    DOGE: "https://assets.coingecko.com/coins/images/5/large/dogecoin.png",
    XRP: "https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png",
    SOL: "https://assets.coingecko.com/coins/images/4128/large/solana.png",
    BNB: "https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png",
  };

  // Function to get coin icon based on symbol
  const getCoinIcon = (cryptoCompareSymbol) =>
    iconMappings[cryptoCompareSymbol] || "https://via.placeholder.com/50";

  // Number of notifications
  const notificationCount = notifications.length;

  // Function to handle clicking on the notification bell
  const handleNotificationClick = () => {
    setNotificationOpen(!notificationOpen);
    // Close other menus if necessary
    setMenuOpen(false);
    setMobileMenuOpen(false);
  };

  return (
    <>
      {/* Header section */}
      <header className="bg-gray-800 z-30 fixed top-0 left-0 w-full shadow-lg">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            {/* Left section - Logo and search bar */}
            <div className="flex items-center gap-4">
              {/* Logo */}
              <Link className="block" to="/">
                <span className="sr-only">Home</span>
                <img src={logo} className="h-9" alt="Swaggycoins Logo" />
              </Link>

              {/* Search bar (visible only on desktop) */}
              <div className="hidden md:flex md:flex-1">
                <div className="relative flex items-center">
                  <MagnifyingGlassIcon className="absolute left-3 h-5 w-5 text-gray-500" />
                  <input
                    type="text"
                    placeholder={t("Search markets")}
                    className="pl-10 pr-3 py-2 h-10 bg-gray-600 text-sm text-gray-200 rounded-full w-full md:w-64 lg:w-80 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {/* Filtered coin dropdown */}
                  {searchTerm && filteredCoins.length > 0 && (
                    <ul className="absolute top-full mt-1 left-0 right-0 bg-gray-600 text-gray-200 rounded-lg max-h-60 overflow-y-auto z-10">
                      {filteredCoins.map((coin) => (
                        <li
                          key={coin.id}
                          className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center"
                          onClick={() => handleCoinClick(coin.name, coin.id, coin.type)}
                        >
                          <img
                            src={getCoinIcon(coin.cryptoCompareSymbol)}
                            alt={coin.name}
                            className="w-6 h-6 mr-2 rounded-full"
                          />
                          {coin.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            {/* Right section - Global links and user menu */}
            <div className="flex items-center gap-4">
              {/* Global navigation links (visible only on desktop) */}
              <nav aria-label="Global">
                <ul className="flex items-center gap-6 text-sm">
                  <li>
                    <LanguageSwitcher />
                  </li>
                  <li className="hidden md:block">
                    <Link
                      to="/about"
                      className="text-white hover:text-gray-200 transition duration-200"
                    >
                      {t('About Us')}
                    </Link>
                  </li>
                </ul>
              </nav>

              {/* Authentication buttons or user dropdown */}
              <div className="flex items-center gap-2">
                {!isAuthenticated && !localStorage.getItem("user") ? (
                  <>
                    {/* Login and Sign Up buttons (for unauthenticated users) */}
                    <button
                      className="rounded-md hover:bg-purple-700 px-4 py-2 text-sm font-medium text-white transition duration-200"
                      onClick={openLoginForm}
                    >
                      {t('Login')}
                    </button>

                    <button
                      className="rounded-md bg-yellow-400 hover:bg-yellow-500 px-5 py-2 text-sm font-medium text-gray-800 transition duration-200"
                      onClick={openRegisterForm}
                    >
                      {t('Sign Up')}
                    </button>
                  </>
                ) : (
                  <>
                    {/* Desktop version */}
                    <div className="hidden md:flex items-center gap-4 relative">
                      {/* NotificationBell */}
                      <NotificationBell
                        notificationCount={notificationCount}
                        onClick={handleNotificationClick}
                      />

                      {/* Notification Dropdown */}
                      {notificationOpen && (
                        <div className="absolute right-0 top-10 w-96 bg-gray-700 text-white shadow-lg rounded-md z-50">
                          <div className="p-4">
                            <div className="flex items-center justify-between mb-4">
                              <h3 className="text-lg font-semibold">
                                {t('Notifications')}
                              </h3>
                              {notifications.length > 0 && (
                                <span
                                  className="text-sm text-gray-400 hover:text-gray-200 cursor-pointer"
                                  onClick={handleMarkAllAsRead}
                                >
                                  {t('Mark all as read')}
                                </span>
                              )}
                            </div>
                            
                            {/* Add Tab Navigation */}
                            <div className="flex mb-4 border-b border-gray-600">
                              <button
                                className={`px-4 py-2 w-1/2 ${
                                  activeTab === 'personal'
                                    ? 'border-b-2 border-purple-500 text-white'
                                    : 'text-gray-400 hover:text-gray-200'
                                }`}
                                onClick={() => setActiveTab('personal')}
                              >
                                {t('Personal')}
                              </button>
                              <button
                                className={`px-4 py-2 w-1/2 ${
                                  activeTab === 'news'
                                    ? 'border-b-2 border-purple-500 text-white'
                                    : 'text-gray-400 hover:text-gray-200'
                                }`}
                                onClick={() => setActiveTab('news')}
                              >
                                {t('News')}
                              </button>
                            </div>

                            {/* Notification Content */}
                            {notifications.filter(notification => 
                              activeTab === 'personal' 
                                ? notification.type === 'transactions'
                                : notification.type === 'news'
                            ).length > 0 ? (
                              <ul className="space-y-2 max-h-48 overflow-y-auto">
                                {notifications
                                  .filter(notification => 
                                    activeTab === 'personal' 
                                      ? notification.type === 'transactions'
                                      : notification.type === 'news'
                                  )
                                  .map((notification) => (
                                    <li
                                      key={notification.id}
                                      className="pb-2 bg-gray-600 hover:bg-gray-500 rounded-md px-2 py-1"
                                    >
                                      <div className="flex items-center justify-between">
                                        <div className="p-2 cursor-pointer">
                                          <p className="font-semibold">
                                            {notification.data.title}
                                          </p>
                                          <p className="text-gray-400 text-sm">
                                            {notification.data.message}
                                          </p>
                                        </div>
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleNotificationRead(
                                              notification.id
                                            )
                                          }
                                        >
                                          <EnvelopeIcon className="h-6 w-6 text-gray-400 hover:text-gray-200 transition duration-200" />
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            ) : (
                              <div className="text-center py-4 mb-4 rounded-md bg-gray-600">
                                <p className="text-gray-400">
                                  {t('No')} {activeTab} {t('notifications')}.
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* User dropdown */}
                      <div
                        className="relative text-left"
                        onMouseEnter={() => {
                          setMenuOpen(true);
                          setNotificationOpen(false);
                        }}
                        onMouseLeave={() => setMenuOpen(false)}
                      >
                        <button
                          onClick={handleUserClick}
                          className="inline-flex justify-center items-center rounded-md px-4 py-2 text-sm font-medium text-white hover:text-gray-200 focus:outline-none"
                        >
                          <UserCircleIcon className="h-6 w-6 mr-1" />
                          {user ? user.name : (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : t("User"))}
                        </button>

                        {/* Dropdown with balance, deposit button, dashboard, settings, and logout */}
                        {menuOpen && (
                          <div className="absolute right-0 top-full w-56 origin-top-right bg-gray-700 divide-y divide-gray-600 rounded-md shadow-lg z-50">
                            {/* Balance and Deposit */}
                            <div className="px-4 py-2 text-sm text-gray-200 flex items-center justify-between">
                              <span>{t('Balance')}: ${balance ?? 0}</span>
                              <button
                                className="bg-yellow-400 text-black px-3 py-1 rounded-md hover:bg-yellow-500 transition duration-200"
                                onClick={handleDeposit}
                              >
                                {t('Deposit')}
                              </button>
                            </div>

                            {/* Dashboard Link */}
                            <Link
                              to="/dashboard"
                              className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-600 rounded-md"
                              onClick={() => setMenuOpen(false)} // Close menu on click
                            >
                              {t('Dashboard')}
                            </Link>

                            {/* Settings Link */}
                            <Link
                              to="/user/settings"
                              className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-600 rounded-md"
                              onClick={() => setMenuOpen(false)} // Close menu on click
                            >
                              {t('Settings')}
                            </Link>

                            {/* Logout Button */}
                            <button
                              onClick={logout}
                              className="w-full text-left px-4 py-2 text-sm text-gray-200 hover:bg-gray-600 rounded-md"
                            >
                              {t('Logout')}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Mobile version */}
                    <div className="md:hidden flex items-center gap-2 relative">
                      {/* User Name Button */}
                      <button
                        onClick={handleUserClick}
                        className="inline-flex justify-center items-center rounded-md px-2 py-2 text-sm font-medium text-white hover:text-gray-200 focus:outline-none"
                      >
                        <UserCircleIcon className="h-6 w-6 mr-1" />
                        {user ? user.name : (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : t("User"))}
                      </button>

                      {/* NotificationBell */}
                      <NotificationBell
                        notificationCount={notificationCount}
                        onClick={handleNotificationClick}
                      />

                      {/* Hamburger icon */}
                      <button
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        className="text-white focus:outline-none"
                        aria-label="Toggle mobile menu"
                      >
                        {mobileMenuOpen ? (
                          <XMarkIcon className="h-6 w-6" />
                        ) : (
                          <Bars3Icon className="h-6 w-6" />
                        )}
                      </button>

                      {/* Sliding notification panel from right */}
                      {notificationOpen && (
                        <>
                          <div className="fixed top-0 right-0 h-full w-64 bg-gray-800 transform translate-x-0 transition-transform duration-300 ease-in-out z-50">
                            <div className="flex flex-col h-full p-4">
                              {/* Header of notification panel */}
                              <div className="flex items-center justify-between mb-8">
                                <h2 className="text-white text-lg font-semibold">
                                  {t('Notifications')}
                                </h2>
                                <button
                                  onClick={() => setNotificationOpen(false)}
                                  className="text-white focus:outline-none"
                                >
                                  <XMarkIcon className="h-6 w-6" />
                                </button>
                              </div>

                              {/* Notification list */}
                              <div className="flex-1 overflow-y-auto">
                                {notifications.length > 0 ? (
                                  <ul className="space-y-4">
                                    {notifications.map((notification) => (
                                      <li
                                      key={notification.id}
                                      className="pb-2 bg-gray-600 hover:bg-gray-500 rounded-md px-2 py-1"
                                    >
                                      <div className="flex items-center justify-between">
                                        <div className="p-2 cursor-pointer">
                                          <p className="font-semibold text-white">
                                            {notification.data.title}
                                          </p>
                                          <p className="text-gray-400 text-sm">
                                            {notification.data.message}
                                          </p>
                                        </div>
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleNotificationRead(
                                              notification.id
                                            )
                                          }
                                        >
                                          <EnvelopeIcon className="h-6 w-6 text-gray-400 hover:text-gray-200 transition duration-200" />
                                        </div>
                                      </div>
                                    </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p className="text-gray-400">
                                    {t('No new notifications.')}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Overlay when notification panel is open */}
                          <div
                            className="fixed inset-0 bg-black bg-opacity-50 z-40"
                            onClick={() => setNotificationOpen(false)}
                          ></div>
                        </>
                      )}

                      {/* Sliding mobile menu from right */}
                      {mobileMenuOpen && (
                        <div
                          className={`fixed top-0 right-0 h-full w-64 bg-gray-800 transform ${
                            mobileMenuOpen
                              ? "translate-x-0"
                              : "translate-x-full"
                          } transition-transform duration-300 ease-in-out z-50`}
                        >
                          <div className="flex flex-col h-full p-4">
                            {/* Header of mobile menu with 'Menu' title and close button */}
                            <div className="flex items-center justify-between mb-8">
                              <h2 className="text-white text-lg font-semibold">
                                {t('Menu')}
                              </h2>
                              <button
                                onClick={() => setMobileMenuOpen(false)}
                                className="text-white focus:outline-none"
                              >
                                <XMarkIcon className="h-6 w-6" />
                              </button>
                            </div>

                            {/* Balance and Deposit Button at the Top */}
                            <div className="flex items-center justify-between text-white mb-4">
                              <span>{t('Balance')}: ${balance ?? 0}</span>
                              <button
                                className="bg-yellow-400 text-black px-3 py-1 rounded-md hover:bg-yellow-500 transition duration-200"
                                onClick={handleDeposit}
                              >
                                {t('Deposit')}
                              </button>
                            </div>

                            {/* Navigation links */}
                            <nav className="flex-1">
                              <ul className="space-y-4">
                                {/* Dashboard Link */}
                                <li>
                                  <Link
                                    to="/dashboard"
                                    className="block text-white hover:text-gray-200 transition duration-200"
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    {t('Dashboard')}
                                  </Link>
                                </li>

                                {/* About Us Link */}
                                <li>
                                  <Link
                                    to="/about"
                                    className="block text-white hover:text-gray-200 transition duration-200"
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    {t('About Us')}
                                  </Link>
                                </li>

                                {/* Settings Link */}
                                <li>
                                  <Link
                                    to="/user/settings"
                                    className="block text-white hover:text-gray-200 transition duration-200"
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    {t('Settings')}
                                  </Link>
                                </li>
                              </ul>
                            </nav>

                            {/* Logout Button */}
                            <button
                              onClick={() => {
                                logout();
                                setMobileMenuOpen(false);
                              }}
                              className="w-full text-left px-4 py-2 text-sm text-white bg-red-500 hover:bg-red-600 rounded-md"
                            >
                              {t('Logout')}
                            </button>
                          </div>
                        </div>
                      )}

                      {/* Overlay when mobile menu is open */}
                      {(mobileMenuOpen || notificationOpen) && (
                        <div
                          className="fixed inset-0 bg-black bg-opacity-50 z-40"
                          onClick={() => {
                            setMobileMenuOpen(false);
                            setNotificationOpen(false);
                          }}
                        ></div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Modal for Login/Register */}
      {showModal && (
        <LoginForm
          onClose={toggleModal}
          mode={formMode}
          setFormMode={setFormMode}
          apiHost={apiHost}
          apiVersion={apiVersion}
        />
      )}
    </>
  );
};

export default NavBar;
