import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ScrollingLiveNewsTicker = () => {
  const [newsItems, setNewsItems] = useState([]);
  const tickerRef = useRef(null);
  const [animationDuration, setAnimationDuration] = useState(240); // Default duration for desktop
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          'https://min-api.cryptocompare.com/data/v2/news/?lang=EN'
        );
        const data = await response.json();

        // Extract the titles from the news data
        const titles = data.Data.map((item) => item.title);
        setNewsItems(titles);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();

    // Optional: Refresh news every 5 minutes
    const interval = setInterval(fetchNews, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (tickerRef.current) {
      const tickerWidth = tickerRef.current.scrollWidth;
      // Calculate duration: longer content takes longer to scroll
      // Adjust the divisor to control speed. Higher divisor = slower speed
      const duration = tickerWidth / 120; // Adjust this value as needed
      // Set a minimum duration to ensure readability
      setAnimationDuration(Math.max(duration, 240)); // Minimum 240s
    }
  }, [newsItems]);

  // Duplicate the news items multiple times for seamless scrolling
  const duplicatedNewsItems = [...newsItems, ...newsItems, ...newsItems];

  return (
    <div
      className="w-full bg-gray-800 text-white py-4 px-2 rounded-md shadow-lg overflow-hidden flex items-center"
      aria-label="Live news ticker"
    >
      {/* Live Badge and Label */}
      <div className="flex items-center mr-1 flex-shrink-0">
        <span className="bg-red-500 text-xs uppercase px-2 py-1 rounded-full animate-pulse">
          {t('Live')}
        </span>
        {/* <span className="text-sm md:text-base">Latest News:</span> */}
      </div>

      {/* Scrolling News */}
      <div className="flex-1 overflow-hidden">
        <div
          ref={tickerRef}
          className="whitespace-nowrap flex animate-scroll"
          style={{
            animation: `scroll ${animationDuration}s linear infinite`,
          }}
        >
          {duplicatedNewsItems.map((item, index) => (
            <div
              key={index}
              className="inline-block mx-8 text-sm md:text-base hover:text-yellow-300 transition-colors duration-300 cursor-pointer"
            >
              &#x2022; {item}
            </div>
          ))}
        </div>
      </div>

      {/* Custom Styles for Slower Scrolling Animation */}
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-scroll {
          display: inline-block;
          /* Animation duration is set dynamically via inline styles */
        }

        /* Pause animation on hover */
        .animate-scroll:hover {
          animation-play-state: paused;
        }

        /* Adjust animation duration for smaller screens */
        @media (max-width: 768px) {
          /* Override the animation duration for mobile */
          .animate-scroll {
            /* Assuming 360s is set via inline styles based on ticker width */
          }
        }
      `}</style>
    </div>
  );
};

export default ScrollingLiveNewsTicker;
