// src/components/About.jsx

import React, { useState } from 'react';
import { FaCoins, FaTrophy, FaUsers } from 'react-icons/fa';
import { motion } from 'framer-motion';
import LoginForm from './LoginForm'; // Ensure the path is correct
import { useAuth } from "../AuthContext";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { isAuthenticated } = useAuth();

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState('login'); // 'login' or 'register'
  const { t, i18n } = useTranslation();

  // Functions to control the modal
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const openLoginForm = () => {
    setFormMode('login');
    toggleModal();
  };

  const openRegisterForm = () => {
    setFormMode('register');
    toggleModal();
  };

  return (
    <>
      <div className="container mx-auto px-4 py-12 mt-20 text-white">
        <h1 className="text-4xl font-bold mb-8 text-center">{t('About Us')}</h1>

        {/* Hero Section */}
        <div className="flex flex-col items-center mb-12">
          <motion.h2
            className="text-2xl font-semibold mb-4 text-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {t('We are the Game of Skill platform')}
          </motion.h2>
          <p className="text-lg leading-relaxed max-w-2xl text-center mb-4">
            {t('The outcome relies heavily on your knowledge, analysis, and strategy in predicting price movements. Success would depend on factors such as understanding market trends, studying historical data, and analyzing market behavior.')}
          </p>
          <p className="text-lg leading-relaxed max-w-2xl text-center">
            {t('On our platform, you can discover rooms dedicated to the most popular stocks and cryptocurrencies. Make predictions, win exciting rewards, and become part of a vibrant, growing community!')}
          </p>
        </div>

        {/* Feature Sections */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {/* Feature 1 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-b from-blue-900 to-blue-700 p-6 rounded-lg shadow-lg flex flex-col items-center"
          >
            <FaCoins className="text-yellow-400 text-6xl mb-4" />
            <h2 className="text-xl font-semibold mb-2">{t('Explore Crypto Rooms')}</h2>
            <p className="text-center">
              {t('Each Room is updated every 24 hours. Choose a Room and predict the price of the selected coin 24 hours in advance.')}
            </p>
          </motion.div>

          {/* Feature 2 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-b from-green-900 to-green-700 p-6 rounded-lg shadow-lg flex flex-col items-center"
          >
            <FaTrophy className="text-yellow-400 text-6xl mb-4" />
            <h2 className="text-xl font-semibold mb-2">{t('Real-Time Updates & Rewards')}</h2>
            <p className="text-center">
              {t('Results are updated daily at UTC+0. Payouts to the top three most accurate predictions are automatically transferred to your wallet.')}
            </p>
          </motion.div>

          {/* Feature 3 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-b from-purple-900 to-purple-700 p-6 rounded-lg shadow-lg flex flex-col items-center"
          >
            <FaUsers className="text-yellow-400 text-6xl mb-4" />
            <h2 className="text-xl font-semibold mb-2">{t('Community Payouts')}</h2>
            <p className="text-center">
              {t('Even if you don\'t win, you won\'t lose everything, as the remaining pool is distributed equally among all other participants.')}
            </p>
          </motion.div>
        </div>

        {/* Testimonials Section */}
        <div className="mb-12">
          <h2 className="text-3xl font-semibold mb-6 text-center">{t('What Our Users Say')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Testimonial 1 */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
            >
              <p className="text-center">
                "This platform has enhanced my understanding of crypto markets and rewarded me for my predictions!"
              </p>
              <p className="text-right mt-4">- Alex J.</p>
            </motion.div>
            {/* Testimonial 2 */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
            >
              <p className="text-center">
                "A fun and engaging way to apply my market analysis skills. Highly recommended!"
              </p>
              <p className="text-right mt-4">- Maria S.</p>
            </motion.div>
            {/* Testimonial 3 */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
            >
              <p className="text-center">
                "Even when I don't win, I still enjoy being part of the community and learning from others."
              </p>
              <p className="text-right mt-4">- Liam T.</p>
            </motion.div>
          </div>
        </div>

        {/* Call to Action */}
        { !isAuthenticated ? <div className="text-center">
            <button
              onClick={openRegisterForm} // Use the function to open the modal
              className="inline-block bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-semibold py-3 px-6 rounded-full shadow-lg transition duration-300"
            >
              {t('Join Now and Start Winning!')}
            </button>
          </div>
          : <></>
        }
      </div>

      {/* Render the modal if showModal is true */}
      {showModal && (
        <LoginForm
          onClose={toggleModal}
          mode={formMode}
          setFormMode={setFormMode}
          apiHost="your-api-host" // Replace with actual API host if needed
          apiVersion="v1" // Replace with actual API version if needed
        />
      )}
    </>
  );
};

export default About;
