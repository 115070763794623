import React, { useState, useEffect } from 'react';
import { MoreVertical, MessageSquare, Trash2 } from 'lucide-react';
import CommentInput from './CommentInput';
import { useAuth } from "../AuthContext";
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';

const SingleComment = ({
    poolId,
    commentId,
    authorName,
    authorId,
    content,
    timestamp,
    replies,
    parentCommentId,
    setComments,
    comments,
    fetchComments,
}) => {
  const { user, isAuthenticated } = useAuth();
  const { apiHost, apiVersion } = useApi();
  const { t, i18n } = useTranslation();
  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const commentDate = new Date(timestamp);
    const diffMs = now - commentDate;
    
    // Convert to different time units
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffMonths = (now.getFullYear() - commentDate.getFullYear()) * 12 + 
      (now.getMonth() - commentDate.getMonth());

    if (diffMinutes < 1) return t('Just now');
    if (diffMinutes < 60) return `${diffMinutes} ${diffMinutes === 1 ? t('minute ago') : t('minutes ago')}`;
    if (diffHours < 24) return `${diffHours} ${diffHours === 1 ? t('hour ago') : t('hours ago')}`;
    if (diffDays < 30) return `${diffDays} ${diffDays === 1 ? t('day ago') : t('days ago')}`;
    if (diffMonths === 1) return `1 ${t('month ago')}`;
    return `${diffMonths} ${t('months ago')}`;
  };

  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const deleteComment = async (id) => {
    const response = await fetch(`${apiHost}${apiVersion}/market/comments/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('sanctum_token')}`
      }
    });
    const data = await response.json();
    
    if (parentCommentId) {
      // For nested replies, find the parent comment and remove the reply
      setComments(comments.map(comment => {
        if (comment.id === parentCommentId) {
          return {
            ...comment,
            replies: comment.replies.filter(reply => reply.id !== id)
          };
        }
        return comment;
      }));
    } else {
      // For top-level comments, remove the comment directly
      setComments(comments.filter(comment => comment.id !== id));
    }
  };

  const linkifyContent = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    return text.split(urlRegex).map((part, i) => {
      if (part.match(urlRegex)) {
        return <a key={i} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{part}</a>;
      }
      return part;
    });
  };

  return (
    <div className="comment-transition">
      <div className="flex space-x-4">
        <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
          <span className="text-gray-600 font-medium">
            {authorName.split(' ').map(name => name[0]).join('')}
          </span>
        </div>
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <h3 className="font-medium">{authorName}</h3>
              <span className="text-sm">{formatTimestamp(timestamp)}</span>
            </div>
            {(isAuthenticated && authorId === user.id) && (
              <div className="relative">
                <button 
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <MoreVertical size={16} />
                </button>
                
                {showDropdown && (
                  <div className="absolute right-0 mt-1 w-36 bg-white rounded-md shadow-lg border border-gray-200 z-10">
                    <button
                      className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100 flex items-center space-x-2"
                      onClick={() => {
                        deleteComment(commentId);
                        setShowDropdown(false);
                      }}
                    >
                      <Trash2 size={14} />
                      <span>{t('Delete')}</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <p className="mt-1">{linkifyContent(content)}</p>
          <div className="mt-2 flex flex-col space-y-2">
            <button 
              onClick={() => setShowReplyForm(!showReplyForm)}
              className="flex items-center space-x-2 text-sm hover:text-gray-500 reply-button px-2 py-1 rounded-full w-fit"
            >
              <MessageSquare size={16} />
              <span>{t('Reply')}</span>
            </button>

            {showReplyForm && (
              <CommentInput
                onCancel={() => setShowReplyForm(false)}
                placeholder={t("Write your reply") + "..."}
                isReply={true}
                parentCommentId={parentCommentId ?? commentId}
                id={poolId}
                fetchComments={fetchComments}
              />
            )}
          </div>
        </div>
      </div>
      {replies && (
        <div className="ml-14 mt-4 space-y-4">
          {replies}
        </div>
      )}
    </div>
  );
};
export default SingleComment;