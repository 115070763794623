import React, { useState, lazy, Suspense } from 'react';
import { Smile } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { useApi } from "../contexts/ApiContext";
import { useAuth } from "../AuthContext";
import { useTranslation } from 'react-i18next';

const EmojiPicker = lazy(() => import('emoji-picker-react'));
const CommentInput = ({
  placeholder = "Write your comment...",
  onCancel = () => {},
  initialValue = "",
  isReply = false,
  parentCommentId = null,
  id = null,
  fetchComments = () => {}
}) => {
    const [comment, setComment] = useState(initialValue);
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    const { apiHost, apiVersion } = useApi();
    const { user, isAuthenticated } = useAuth();
    const { t, i18n } = useTranslation();
    const handleSubmit = () => {
        if (comment.trim()) {
            addComment(comment, parentCommentId);
            setComment("");

            if (isReply) {
              onCancel();
            }
        }
    };
    const onEmojiClick = (emojiObject) => {
        setComment(prevComment => prevComment + emojiObject.emoji);
    };

    const addComment = async (text, parentCommentId = null) => {
        try {
          const token = localStorage.getItem('sanctum_token');
          if (!token) {
            throw new Error(t('No authentication token found'));
          }
    
          const response = await fetch(`${apiHost}${apiVersion}/market/comments/${id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ content: text, parent_comment_id: isReply ? parentCommentId : null })
          });
    
          if (!response.ok) {
            throw new Error(`${t('HTTP error! status')}: ${response.status}`);
          }

          fetchComments();
        } catch (error) {
          console.error('Error adding comment:', error);
          // You might want to add error handling UI feedback here
        }
    };

    const addReplyToComment = (comments, parentId, newReply) => {
        return comments.map(comment => {
          if (comment.id === parentId) {
            return {
              ...comment,
              replies: [...comment.replies, newReply]
            };
          }
          if (comment.replies.length > 0) {
            return {
              ...comment,
              replies: addReplyToComment(comment.replies, parentId, newReply)
            };
          }
          return comment;
        });
    };

    return (
        <div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-sm">
            <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                <span className="text-gray-600 font-medium">{user.name.split(' ').map(name => name[0]).join('')}</span>
            </div>
            <div className="flex-1 relative">
                <input
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit();
                        }
                    }}
                    placeholder={t(placeholder)}
                    className="w-full px-4 py-2 text-gray-900 rounded-full border border-gray-200 focus:outline-none focus:border-gray-300 comment-input"
                />
                <Popover>
                  <PopoverTrigger asChild>
                      <button 
                      className="absolute right-0 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-gray-600 transition-colors"
                      onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}
                      >
                      <Smile size={20} />
                      </button>
                  </PopoverTrigger>
                  <PopoverContent className="p-0 border-none">
                      <Suspense fallback={<div>Loading...</div>}>
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                      </Suspense>
                  </PopoverContent>
                </Popover>
            </div>
            {isReply && (
                <button
                    onClick={onCancel}
                    className="px-6 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {t('Cancel')}
                </button>
            )}
            <button
                onClick={handleSubmit}
                className="px-6 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={!comment.trim()}
            >
                {t('Post')}
            </button>
        </div>
    );
};
export default CommentInput;