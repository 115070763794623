import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import pendingPaymentImage from '../assets/images/pending-payment.png';
import { useAuth } from "../AuthContext";
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
const WithdrawalModal = ({ onClose, isWithdrawalProcessed, currnecyList, fetchBalance, finsolBankList }) => {
	// State variables
	const { hasBonus, totalBonus, canWithdrawBonus } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [walletAddress, setWalletAddress] = useState("");
	const [amount, setAmount] = useState(0);
	const [bonus, setBonus] = useState(0);
	const [currency, setCurrency] = useState("");
	const [gateway, setGateway] = useState("");
	const [error, setError] = useState("");
	const [coinList, setCoinList] = useState(currnecyList);
    const { t, i18n } = useTranslation();
	const [coinGroupList, setCoinGroupList] = useState({
		"Crypto Currencies": [],
		"Fiat Currencies": [],
	});
	const [bankSelect, setBankSelect] = useState("");
	const [filteredBankList, setFilteredBankList] = useState(
		Array.isArray(finsolBankList) ? finsolBankList : Object.values(finsolBankList)
	);
	const [minAmount, setMinAmount] = useState(100);
	const [maxAmount, setMaxAmount] = useState(null);
	const [processedPayment, setProcessedPayment] = useState(isWithdrawalProcessed);
	const [paymentType, setPaymentType] = useState("");
	const { apiHost, apiVersion } = useApi();
	const [currentStep, setCurrentStep] = useState(1);

	// Add step validation functions
	const canProceedToStep2 = () => currency !== "";
	const canProceedToStep3 = () => walletAddress !== "" && (paymentType === "crypto" || bankSelect !== "");
	const canProceedToFinal = () => amount > 0;

	useEffect(() => {
		setCoinList(currnecyList);

		let fiatC = [];
		let cryptoC = [];

		currnecyList.forEach(currency => {
			if (currency.value !== "IDR") {  // Filter out IDR
				if (currency.type == "fiat") {
					fiatC.push(currency)
				} else if (currency.type == "crypto") {
					cryptoC.push(currency)
				}
			}
		});

		setCoinGroupList({
			"Crypto Currencies": cryptoC,
			"Fiat Currencies": fiatC,
		})
	}, [currnecyList]);

	useEffect(() => {
		const selectedCurrency = coinList.find(item => item.value == currency);
		
		if( selectedCurrency ) {
			setPaymentType(selectedCurrency.type) // crypto or fiat
			setGateway(selectedCurrency.gateway);

			if( selectedCurrency.deposit_max <= 0 ) {
				setMinAmount(Math.floor(selectedCurrency.withdraw_min * 100) / 100);
				setMaxAmount(null)
			} else {
				setMinAmount(Math.floor(selectedCurrency.withdraw_min * 100) / 100);
				setMaxAmount(Math.floor(selectedCurrency.withdraw_max * 100) / 100) // Round down to 2 decimal places
			}
		};
	}, [currency, setGateway]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		// Validate all required fields
		if (!currency) {
			setError(t("Please select a currency"));
			return;
		}

		if( paymentType === "fiat" && !bankSelect ) {
			setError(t("Please select a bank"));
			return;
		}

		if (!walletAddress) {
			setError(t("Please enter a wallet address"));
			return;
		}

		if( amount <= 0 ) {
			setError(t("Amount is required"));
			return;
		}

		if( amount < minAmount ) {
			setError(t("Minimum withdrawal amount is 100"));
			return;
		}

		if ( canWithdrawBonus && Number(bonus) > Number(totalBonus) ) {
			setError(t("Bonus input is greater than Total Bonus"));
			return;
		}

		setError("");

		setIsLoading(true);

		try {
			const token = localStorage.getItem("sanctum_token");

			const response = await fetch(`${apiHost}${apiVersion}/withdraw/expay/create`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					amount: amount,
					currency: currency,
					gateway: gateway,
					wallet_address: walletAddress,
					bonus: bonus,
					bank_select: paymentType === 'fiat' ? bankSelect : null
				}),
			});

			const contentType = response.headers.get("content-type");

			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();
				console.log(data)
				if ( response.ok ) {
					fetchBalance();
					
					setProcessedPayment(true);

					if( paymentType == "crypto" ) {
						if( !data.success ) {
							alert(data.message);
						}
					}
				} else {
					setError(`${t('Sorry')} ` + data.message);
				}
			} else {
				const text = await response.text();
				console.error("Non-JSON response:", text);
				setError(`${t('Unexpected response format')}: ${text}`);
			}

		} catch (error) {
			console.error('Fetch Error:', error);
			setError(error.message || t("An error occurred, please try again later."));
		} finally {
			setIsLoading(false);
		}
	};

	const WithdrawalStatus = () => {
		return <CryptoWithdrawalStatus />
	}

	const CryptoWithdrawalStatus = () => {
		return <>
			<div className="p-6">
				<div className="flex items-center flex-col text-center mb-2">
					<img src={pendingPaymentImage} alt="" className="w-24" />
					<h2 className="text-xl sm:text-2xl font-semibold text-slate-900 mt-6">
						{t('Withdrawal is processing')}
					</h2>
					<p className="text-sm sm:text-base text-slate-600 mt-2">
						{t('Your withdrawal is processing, please check your withdrawal address in a moment')}
					</p>
				</div>
			</div>
		</>
	}

	const renderStepContent = () => {
		switch (currentStep) {
			case 1:
				return (
					<div className="mb-4">
						<label htmlFor="currency" className="text-sm font-semibold text-slate-900">
							{t('Select Currency')}
						</label>
						<select 
							className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
							name="currency"
							value={currency}
							id="currency"
							onChange={(e) => setCurrency(e.target.value)}
							required
						>
							<option value="">{t('Select Currency')}</option>
							{Object.entries(coinGroupList).map(([groupLabel, options]) => (
								options.length > 0 &&
								<optgroup key={groupLabel} label={t(groupLabel)}>
									{options.map((option) => (
										<option key={option.value} value={option.value}>
											{`${option.name}`}
										</option>
									))}
								</optgroup>
							))}
						</select>
					</div>
				);
			case 2:
				return (
					<div className="mb-4">
						{paymentType === 'crypto' ? (
							<>
								<label htmlFor="walletAddress" className="text-sm font-semibold text-slate-900">
									{t('Wallet Address')}
								</label>
								<input
									name="walletAddress"
									type="text"
									value={walletAddress}
									onChange={(e) => setWalletAddress(e.target.value)}
									required
									className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
								/>
							</>
						) : (
							<>
								<label htmlFor="bankSelect" className="text-sm font-semibold text-slate-900">
									{t('Select Bank')}
								</label>
								<select
									name="bankSelect"
									value={bankSelect}
									onChange={(e) => setBankSelect(e.target.value)}
									required
									className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
								>
									<option value="">{t('Select a bank')}</option>
									{filteredBankList.map((bank) => (
										<option key={bank.id} value={bank.id}>
											{bank.bank_name}
										</option>
									))}
								</select>
								{bankSelect && (
									<div className="mt-4">
										<label htmlFor="accountNumber" className="text-sm font-semibold text-slate-900">
											{t('Account Number')}
										</label>
										<input
											name="accountNumber"
											type="text"
											value={walletAddress}
											onChange={(e) => setWalletAddress(e.target.value)}
											required
											className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
											placeholder={t("Enter your account number")}
										/>
									</div>
								)}
							</>
						)}
					</div>
				);
			case 3:
				return (
					<>
						<div className="mb-4">
							<label htmlFor="amount" className="text-sm font-semibold text-slate-900">
								{t('Enter Amount')}
							</label>
							<input
								name="amount"
								type="number"
								min={minAmount}
								max={maxAmount}
								value={amount}
								step="0.01"
								onChange={(e) => setAmount(e.target.value)}
								required
								className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
								placeholder="0.00"
							/>
						</div>
						{hasBonus && canWithdrawBonus && (
							<div className="mb-4">
								<label htmlFor="bonus" className="text-sm font-semibold text-slate-900">
									{t('Bonus')} <span className="text-gray-400 text-xs">({t('Optional')}) {`$${totalBonus} ${t('Available')}`}</span>
								</label>
								<input
									name="bonus"
									type="number"
									value={bonus}
									onChange={(e) => setBonus(e.target.value)}
									required
									className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
									placeholder="0.00"
									/>
								<span className="text-gray-400 text-xs">
									{t('You can withdraw your bonus reward if you won a position in a game.')}
								</span>
							</div>
						)}
					</>
				);
			default:
				return null;
		}
	};

	const handlePreviousStep = () => {
		setCurrentStep(prev => prev - 1);
	}

	const handleNextStep = () => {
		const nextStep = currentStep + 1;

		if (nextStep === 2 && gateway === "finsol" || nextStep === 2 && gateway === "expay-fiat") {
			const bankList = finsolBankList[currency];
			setFilteredBankList(
				Array.isArray(bankList) ? bankList : Object.values(bankList)
			);
		}

		setCurrentStep(nextStep);
	}

	return (
		<div
			id="deposit-modal"
			tabIndex="-1"
			className="bg-black/50 fixed inset-0 overflow-y-auto flex items-start justify-center z-50 min-h-screen"
		>
			<div className="relative p-4 sm:p-6 w-full max-w-md bg-white rounded-lg shadow-md mt-8 mb-8">
				<button
					type="button"
					className="absolute top-2 right-2 sm:top-3 sm:right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1 sm:p-1.5"
					onClick={onClose}
				>
					<XMarkIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
					<span className="sr-only">{t('Close popup')}</span>
				</button>
				{ !processedPayment
					? <>
						<div className="p-6">
							<div className="text-center mb-6">
								<h2 className="text-xl sm:text-2xl font-semibold text-slate-900">
									{t('Withdraw')}
								</h2>
								<p className="text-sm sm:text-base text-slate-600 mt-2">
									{t('Step')} {currentStep} {t('of')} 3
								</p>
							</div>
							
							<div className="flex justify-center mb-6">
								<div className="flex items-center">
									{[1, 2, 3].map((step) => (
										<React.Fragment key={step}>
											<div className={`w-8 h-8 rounded-full flex items-center justify-center ${
												currentStep >= step ? 'bg-black text-white' : 'bg-gray-200'
											}`}>
												{step}
											</div>
											{step < 3 && (
												<div className={`w-12 h-1 ${
													currentStep > step ? 'bg-black' : 'bg-gray-200'
												}`} />
											)}
										</React.Fragment>
									))}
								</div>
							</div>

							<form onSubmit={handleSubmit}>
								{renderStepContent()}
								{error && <p className="text-red-500 mb-4">{error}</p>}
								
								<div className="flex gap-3">
									{currentStep > 1 && (
										<button
											type="button"
											onClick={handlePreviousStep}
											className="flex-1 py-3 rounded-lg border border-black text-black font-medium"
										>
											{t('Back')}
										</button>
									)}
									
									{currentStep < 3 ? (
										<button
											type="button"
											onClick={handleNextStep}
											disabled={
												(currentStep === 1 && !canProceedToStep2()) ||
												(currentStep === 2 && !canProceedToStep3())
											}
											className="flex-1 py-3 rounded-lg bg-black text-white font-medium disabled:opacity-50"
										>
											{t('Next')}
										</button>
									) : (
										<button
											type="submit"
											disabled={isLoading || !canProceedToFinal()}
											className="flex-1 py-3 rounded-lg bg-black text-white font-medium disabled:opacity-50"
										>
											{isLoading ? `${t('Loading')}...` : t("Submit")}
										</button>
									)}
								</div>
							</form>
						</div>
					</>
					: <WithdrawalStatus />
				}
			</div>
		</div>
	);
};

export default WithdrawalModal;