import React from 'react';
import { useNavigate } from 'react-router-dom';
import Countdown from '../Countdown';
import { useTranslation } from 'react-i18next';

function BetHistory({ bets, historyLoading }) {
  const { t, i18n } = useTranslation();
  console.log(bets);
  const navigate = useNavigate();
  const profitLoss = (amount, award) => {
    return parseFloat(award - Math.abs(parseFloat(amount)).toFixed(2));
  }

  const handlePoolClick = (coin, pool_id, parent_id, status, type) => {
    if (status === 'Win' || status === 'Lose') {
      navigate(`/event/${type}/${coin}/${pool_id}/history`);
    }else{
      navigate(`/event/${type}/${coin}/${parent_id}`);
    }
  }

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'Win':
        return '!inline-block bg-green-500 !w-[120px] text-center';
      case 'Lose':
        return '!inline-block bg-red-500 !w-[120px] text-center ';
      default:
        return '!inline-block bg-gray-600 !w-[120px] text-center';
    }
  };

  return (
    <div className="mt-10 bg-gray-800 text-white rounded">
      <h3 className="text-xl font-bold mb-4">{t('Bet History')}</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto text-left">
          <thead>
            <tr>
              <th className="w-1/6 px-3 py-2 border-b border-gray-600">{t('Amount')}</th>
              <th className="w-1/4 px-3 py-2 border-b border-gray-600">{t('Coin')}</th>
              <th className="w-1/3 px-3 py-2 border-b border-gray-600">{t('Profit / Loss')}</th>
              <th className="w-1/4 px-3 py-2 border-b border-gray-600">{t('Time')}</th>
            </tr>
          </thead>
          <tbody>
            { historyLoading 
              ? <tr>
                  <td colSpan="4" className='text-center'>{t('Loading')}...</td>
                </tr>
              : <></> 
            }
            {bets.map((bet, index) => (
              <tr 
                key={index} 
                className="border-t border-gray-700 hover:bg-gray-700 cursor-pointer"
                onClick={() => handlePoolClick(
                  bet.order?.pool?.currency?.ticker, 
                  bet.order?.pool?.id,
                  bet.order?.pool?.parent_id,
                  bet.status,
                  bet.order?.pool?.currency?.type
                )}
              >
                <td className="w-1/6 px-3 py-2 border-b border-gray-600">${Math.abs(parseFloat(bet.amount)).toFixed(2)} {bet.is_demo ? '(demo)' : ''}</td>
                <td className="w-1/4 px-3 py-2 border-b border-gray-600 hover:underline">{bet.order?.pool?.name}</td>
                <td className="w-1/3 px-3 py-2 border-b border-gray-600">
                {(bet.status === 'Win' || bet.status === 'Lose') ? (
                    <span className={`${getStatusBadgeClass(bet.status)} text-white px-4 py-1 rounded-full text-sm font-semibold`}>
                      {profitLoss(bet.amount, bet.order?.award) > 0 ? `+${profitLoss(bet.amount, bet.order?.award).toFixed(2)}` : `${profitLoss(bet.amount, bet.order?.award).toFixed(2)}`}
                    </span>
                  ) : (
                      <Countdown targetDate={bet.order.pool.end_time} className={`${getStatusBadgeClass(bet.status)} text-white px-4 py-1 rounded-full text-sm font-semibold`}/>
                  )}
                </td>
                <td className="w-1/4 px-3 py-2 border-b border-gray-600">{new Date(bet.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BetHistory;