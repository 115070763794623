// CoinList.js
import React, { useState, useContext } from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner"; // Adjust path if needed
import { CoinsContext } from "../contexts/CoinsContext";
import Countdown from "./Countdown";
import { Card } from "./ui/card"
import { PinIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next';

// Utility function to format prices with commas
const formatPrice = (price) => {
  if (price === null || price === undefined) return null;
  return `$${parseFloat(price).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 8,
  })}`;
};

// Tabs configuration
const FILTER_TABS = [
  { key: "all", label: "All Rooms" },
  { key: "stocks", label: "Stocks" },
  { key: "crypto", label: "Crypto" },
];

const CoinList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { coinsData, isDataFetched, error } = useContext(CoinsContext);

  // Determine line color based on price trend
  const getLineColor = (trend) => {
    if (trend && trend.length > 0) {
      return trend[trend.length - 1] >= trend[0] ? "#00ff66" : "#e13131"; // Green or Red
    }
    return "gray";
  };

  // Calculate percentage change
  const calculatePercentageChange = (currentPrice, initialPrice) => {
    if (initialPrice === null || initialPrice === 0) return null;
    const change = ((currentPrice - initialPrice) / initialPrice) * 100;
    return change.toFixed(2); // Two decimal places
  };

  // Navigate to coin details
  const handleCoinClick = (symbol, id, type) => {
    const baseSymbol = symbol.toLowerCase(); // e.g. 'DOGE' from 'DOGEUSDT'
    navigate(`/event/${type}/${baseSymbol}/${id}`);
  };

  // Filter logic
  const getFilteredCoins = (coins) => {
    switch (activeFilter) {
      case "stocks":
        return coins.filter((coin) => coin.type === "stocks");
      
      case "crypto":
        return coins.filter((coin) => coin.type === "crypto");

      default:
        // 'all'
        return coins;
    }
  };

  const filteredResults = getFilteredCoins(coinsData).filter((coin) => {
    const symbolLower = coin.symbol.toLowerCase();
    const baseSymbolLower = coin.symbol.slice(0, -4).toLowerCase(); // e.g., remove 'USDT'
    return (
      symbolLower.includes(searchTerm.toLowerCase()) ||
      baseSymbolLower.includes(searchTerm.toLowerCase())
    );
  });

  // Handle tab click
  const handleFilterChange = (filterKey) => {
    setActiveFilter(filterKey);
  };

  return (
    <div className="text-white">
      {/* Page Wrapper */}
      <div className="mx-auto max-w-6xl px-4 py-8">
        {/* Page Header */}
        <div className="mb-6">
          <h1 className="text-2xl font-semibold leading-tight">{t('Explore Markets')}</h1>
          <p className="text-sm text-gray-400">
            {t('Discover featured coins, closing pools, and new rooms.')}
          </p>
        </div>

        {/* Search & Tabs Row */}
        <div className="mb-8 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          {/* Search Input */}
          <div className="relative flex-1">
            <MagnifyingGlassIcon className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder={t('Search markets')}
              className="w-full rounded-md border border-gray-700 bg-gray-800
                         py-2 pl-10 pr-3 text-sm placeholder-gray-400
                         transition-colors duration-200 focus:border-blue-500
                         focus:outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Filter Tabs */}
          <div className="flex flex-wrap gap-2 md:justify-end">
            {FILTER_TABS.map((tab) => (
              <button
                key={tab.key}
                onClick={() => handleFilterChange(tab.key)}
                className={`rounded-md border border-gray-700 bg-gray-800 px-4 py-2 text-sm font-medium
                            transition-colors duration-200 hover:bg-gray-700
                            ${
                              activeFilter === tab.key
                                ? "font-bold text-blue-400"
                                : "text-gray-200"
                            }`}
              >
                {t(tab.label)}
              </button>
            ))}
          </div>
        </div>

        {/* Coin Grid */}
        {isDataFetched ? (
          filteredResults.length > 0 ? (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {filteredResults.map((coin) => {
                const percentageChange = calculatePercentageChange(
                  coin.currentPrice,
                  coin.initialPrice
                );
                const isPositive =
                  percentageChange !== null && parseFloat(percentageChange) >= 0;

                return (coin.currentPrice && percentageChange && coin.priceTrend &&
                  <div
                    key={coin.id}
                    className="flex cursor-pointer flex-col justify-between
                               rounded-lg border border-gray-800 bg-gradient-to-l
                               from-gray-800 to-gray-700 p-4 shadow
                               transition-shadow duration-300 hover:shadow-lg"
                    onClick={() => handleCoinClick(coin.symbol, coin.id, coin.type)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") handleCoinClick(coin.symbol, coin.id, coin.type);
                    }}
                    aria-label={`${t('View details for')} ${coin.symbol.slice(0, -4)}`}
                  >
                    {/* Top Row: Exchange Name, Coin Name and Mini Chart */}
                    <div className="flex justify-between items-start">
                      {/* Exchange Name and Coin Name */}
                      <div>
                        <Card className="inline-flex items-center gap-2 bg-[#2A3E4E] border-0 px-2 py-1">
                          <div className="bg-[#3b82f6]/20 p-0.5 rounded">
                            <PinIcon className="h-3 w-3 text-[#3b82f6] fill-current" />
                          </div>
                          <span className="font-mono text-[0.6rem] text-gray-200">{coin.volume} {t('Vol.')}</span>
                        </Card>
                        <h3 className="text-md font-semibold text-gray-200">{coin.name}</h3>
                      </div>

                      <div className="flex flex-col gap-3">
                        <div className="flex justify-end">
                          <span class="inline-flex items-center rounded-lg bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{coin.timeframe}</span>
                        </div>

                        {/* Mini Price Trend Chart */}
                        <div className="w-20 h-15 pl-3">
                          {coin.priceTrend == null ||
                          coin.priceTrend.length === 0 ? (
                            <Spinner />
                          ) : (
                            <Sparklines data={coin.priceTrend} width={80} height={25} margin={2}>
                              <SparklinesLine
                                color={getLineColor(coin.priceTrend)}
                                style={{ strokeWidth: 2, fill: "none" }}
                              />
                            </Sparklines>
                          )}
                        </div>
                      </div>
                    
                    </div>

                    {/* Price & Change */}
                    <div className="mb-2">
                      <p className="text-sm font-bold">
                        {coin.currentPrice !== null && coin.currentPrice !== undefined
                          ? formatPrice(coin.currentPrice)
                          : <Spinner />}
                      </p>
                      {percentageChange !== null ? (
                        <p
                          className={`text-xs font-semibold ${
                            isPositive ? "text-green-400" : "text-red-400"
                          }`}
                        >
                          {isPositive ? "+" : ""}
                          {percentageChange}%
                        </p>
                      ) : (
                        <Spinner />
                      )}
                    </div>

                    {/* Countdown */}
                    <div className="text-xs text-gray-400">
                      <Countdown targetDate={coin.end_time}>
                        left
                      </Countdown>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-center py-10">
              <p className="text-gray-300">{t('No coins found.')}</p>
            </div>
          )
        ) : (
          !error ? (
            <div className="flex items-center justify-center py-10">
              <Spinner />
            </div>
          ) : (
            <div className="mb-4 text-center text-red-500">
              <p className="text-gray-400">{error}</p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CoinList;
