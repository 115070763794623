import React from 'react'

const Card = ({ className, children, ...props }) => {
  return (
    <div
      className={`rounded-lg shadow-sm ${className}`}
      {...props}
    >
      {children}
    </div>
  )
}

export { Card }

