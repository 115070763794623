import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApi } from "../contexts/ApiContext";
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { apiHost, apiVersion } = useApi();
  const { t, i18n } = useTranslation();

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenFromUrl = params.get('token');
    const emailFromUrl = params.get('email');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, [location]);

  // Password validation function to set strength
  const validatePassword = (password) => {
    const minLength = 8;
    let strength = '';

    if (password.length < minLength) {
      strength = t('Password not long enough');
    } else {
      const hasLetters = /[a-zA-Z]/.test(password);
      const hasNumbers = /[0-9]/.test(password);
      const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      let strengthScore = 0;
      if (hasLetters) strengthScore++;
      if (hasNumbers) strengthScore++;
      if (hasSpecialChars) strengthScore++;

      if (strengthScore === 1) {
        strength = t('Weak');
      } else if (strengthScore === 2) {
        strength = t('Average');
      } else if (strengthScore === 3) {
        strength = t('Strong');
      }
    }

    setPasswordStrength(strength);
    return password.length >= minLength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setLoading(true);

    const isPasswordValid = validatePassword(password);
    if (!isPasswordValid) {
      setPasswordError(t('Please ensure your password meets all the requirements.'));
      setLoading(false);
      return;
    } else {
      setPasswordError('');
    }

    if (password !== passwordConfirmation) {
      setError(t('Passwords do not match.'));
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${apiHost}${apiVersion}/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          email,
          password,
          password_confirmation: passwordConfirmation,
        }),
        credentials: 'include',
      });

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        if (response.ok) {
          setMessage(data.message || t('Password reset successfully.'));
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } else {
          setError(data.message || t('Failed to reset password.'));
        }
      } else {
        const text = await response.text();
        setError(`${t('Unexpected response format')}: ${text}`);
      }
    } catch (err) {
      setError(t('An unexpected error occurred. Please try again.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-semibold text-slate-900">{t('Reset Password')}</h2>
          <p className="text-sm text-slate-600 mt-2">{t('Enter your new password below.')}</p>
        </div>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {message && <p className="text-green-500 text-center mb-4">{message}</p>}

        <form className="space-y-4" onSubmit={handleSubmit}>
          {!email && (
            <input
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-black"
              placeholder={t("Email Address")}
            />
          )}

          <div className="flex justify-between items-center mt-4">
            <label htmlFor="password" className="text-sm font-medium text-gray-700">
              {t('New password (8 characters min)')}
            </label>
            <button
              type="button"
              className="text-blue-600 hover:underline text-sm"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? t('Hide') : t('Show')}
            </button>
          </div>

          <div className="relative">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              required
              value={password}
              onChange={handlePasswordChange}
              className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-black"
              placeholder={t("New Password")}
            />
          </div>

          {/* Password Strength UI */}
          <p className="text-sm mt-2 text-black">
            {t('Password strength')}:{" "}
            <span
              className={
                passwordStrength === "Strong"
                  ? "text-green-500"
                  : passwordStrength === "Average"
                  ? "text-yellow-500"
                  : passwordStrength === "Weak"
                  ? "text-red-500"
                  : passwordStrength === "Password not long enough"
                  ? "text-red-500"
                  : ""
              }
            >
              {passwordStrength}
            </span>
          </p>

          {/* Display message when password is weak or average */}
          {(passwordStrength === 'Weak' || passwordStrength === 'Average') && (
            <p className="text-sm text-gray-600 mt-1">
              {t('A strong password can improve the security of your account should your device be stolen or compromised.')}
            </p>
          )}

          <div className="relative">
            <input
              name="passwordConfirmation"
              type={showPassword ? "text" : "password"}
              required
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-black"
              placeholder={t("Confirm New Password")}
            />
          </div>

          {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}

          <button
            type="submit"
            className={`w-full py-3 rounded-lg bg-black text-white font-medium text-sm focus:ring-2 focus:ring-black ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={loading}
          >
            {loading ? `${t('Resetting')}...` : t('Reset Password')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
