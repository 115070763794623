import React, { useState } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { useApi } from "../../contexts/ApiContext";
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const [email, setEmail] = useState('');
  const [currentPasswordForEmail, setCurrentPasswordForEmail] = useState('');
  const [currentPasswordForPassword, setCurrentPasswordForPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [emailSuccess, setEmailSuccess] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
	const { apiHost, apiVersion } = useApi();
  const { t, i18n } = useTranslation();

  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmailSuccess(t('Your email has been updated successfully.'));
    setErrorMessage('');
    console.log('Email updated to:', email);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage(t('New passwords do not match!'));
      setPasswordSuccess('');
      return;
    }

    try {
      const token = localStorage.getItem("sanctum_token");

      const response = await fetch(`${apiHost}${apiVersion}/update-user-password`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					current_password: currentPasswordForPassword,
					new_password: newPassword,
					new_password_confirmation: confirmPassword,
				}),
			});

			const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
				const data = await response.json();
				console.log(data)
				if ( response.ok ) {
					if( data.success ) {
						setPasswordSuccess(t('Your password has been updated successfully.'));
            setErrorMessage('');
					} else {
						setErrorMessage(data.message);
            setPasswordSuccess('');
					}
				} else {
					setErrorMessage(data.message);
					setPasswordSuccess('');
				}
			} else {
				const text = await response.text();
				console.error("Non-JSON response:", text);
				setErrorMessage(`${t('Unexpected response format')}: ${text}`);
				setPasswordSuccess('');
			}

    } catch (error) {
      setErrorMessage(t('An error occurred while updating your password.'));
      setPasswordSuccess('');
      console.log('Error updating password:', error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-8 rounded-xl shadow-lg text-gray-200">
      <h2 className="text-4xl font-extrabold mb-12 text-center text-gray-100">{t('Account Settings')}</h2>


      <hr className="border-t border-gray-700 mb-16" />

      {/* Change Password Section */}
      <form onSubmit={handlePasswordChange}>
        <h3 className="text-2xl font-semibold mb-8 text-gray-100">{t('Change Password')}</h3>
        {passwordSuccess && (
          <div className="mb-6 text-green-400 font-medium">{passwordSuccess}</div>
        )}
        {errorMessage && (
          <div className="mb-6 text-red-500 font-medium">{errorMessage}</div>
        )}
        <div className="mb-6">
          <label className="block text-gray-400 mb-2">{t('Current Password')}</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiLock className="text-gray-500 mr-3" />
            <input
              type="password"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder={t('Enter your current password')}
              value={currentPasswordForPassword}
              onChange={(e) => setCurrentPasswordForPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-400 mb-2">{t('New Password')}</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiLock className="text-gray-500 mr-3" />
            <input
              type="password"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder={t('Enter your new password')}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-10">
          <label className="block text-gray-400 mb-2">{t('Confirm New Password')}</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiLock className="text-gray-500 mr-3" />
            <input
              type="password"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder={t('Confirm your new password')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full py-4 bg-green-700 text-white rounded-lg hover:bg-green-800 transition duration-300 font-semibold"
        >
          {t('Update Password')}
        </button>
      </form>
    </div>
  );
};

export default Settings;
