import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const languages = [
    { code: 'en', name: 'English', flag: 'gb' },
    { code: 'ph', name: 'Filipino', flag: 'ph' },
    { code: 'id', name: 'Bahasa (Indonesia)', flag: 'id' },
  ];

  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language ?? 'en');
  const [currentFlag, setCurrentFlag] = useState(
    languages.find(lang => lang.code === currentLanguage)?.flag
  );

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLanguage(lang);
    setCurrentFlag(languages.find(l => l.code === lang)?.flag);
    setIsOpen(false);
  };

  return (
    <div className="language-switcher-container" style={{ position: 'relative' }}>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="language-select-button flex items-center justify-center"
      >
        <img 
          src={`https://flagcdn.com/w80/${currentFlag}.png`}
          alt={`Current language flag`}
          style={{ verticalAlign: 'middle', width: '40px' }}
          className="max-w-100 rounded"
        />
      </button>
      
      {isOpen && (
        <div className="language-dropdown bg-gray-800 text-white mt-1.5 w-max overflow-hidden" style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          zIndex: 1000,
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          {languages.map(({ code, name, flag }) => (
            <button
              key={code}
              onClick={() => changeLanguage(code)}
              style={{
                display: 'block',
                width: '100%',
                padding: '8px 12px',
                border: 'none',
                cursor: 'pointer',
                textAlign: 'left'
              }}
              className="hover:bg-gray-600"
            >
              <div className="flex items-center">
                <img 
                  src={`https://flagcdn.com/w40/${flag.toLowerCase()}.png`}
                  alt={`${name} flag`}
                  style={{ marginRight: '8px', verticalAlign: 'middle' }}
                />
                {name}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
