import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useApi } from "../../contexts/ApiContext";
import { useTranslation } from 'react-i18next';
const OTPScreen = ({ onClose, email, onSuccess }) => {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { login, setUserSession } = useAuth();
    const { apiHost, apiVersion } = useApi();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(!email){
            navigate('/', { replace: true });
            
        }
    },[email, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${apiHost}${apiVersion}/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, otp }),
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setUserSession(data.user, data.token, data.expires_at);
                onSuccess();
                onClose();
            } else {
                const data = await response.json();
                setError(data.message);
            }
        } catch (error) {
            setError(t('An error occurred. Please try again later.'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            tabIndex="-1"
            className="bg-black/50 fixed inset-0 flex items-center justify-center z-50"
        >
            <div className="relative p-4 w-full max-w-md bg-white rounded-lg shadow-md">
                <button
                    type="button"
                    className="absolute top-3 right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1.5"
                    onClick={onClose}
                >
                    <XMarkIcon className="h-6 w-6 text-gray-500" />
                    <span className="sr-only">{t('Close popup')}</span>
                </button>

                    <div className="p-6">
                        <div className="text-center mb-4">
                            <h2 className="text-2xl font-semibold text-slate-900">
                                {t('Verify your account')}
                            </h2>
                            <p className="text-sm text-slate-600 mt-2">
                                {t('Please enter the OTP sent to your email to verify your account.')}
                            </p>
                        </div>

                        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <label htmlFor="otp" className="sr-only">{t('OTP')}</label>
                            <input
                                name="otp"
                                type="text"
                                maxLength="6"
                                required
                                value={otp}
                                onChange={(e) => {
                                    // Allow only numbers and limit to 6 characters
                                    const value = e.target.value;
                                    if (/^\d{0,6}$/.test(value)) {
                                        setOtp(value);
                                    }
                                }}
                                className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                                placeholder={t('OTP')}
                            />
                            <button
                                type="submit"
                                className={`w-full py-3 rounded-lg bg-black text-white font-medium text-sm focus:ring-2 focus:ring-black ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={loading}
                            >
                                {loading ? `${t('Loading')}...` : `${t('Verify')}`}
                            </button>
                        </form>
                    </div>
            </div>
        </div>
    );
};

export default OTPScreen;