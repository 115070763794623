import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaTiktok } from 'react-icons/fa'; // Importing Facebook, Instagram, and TikTok icons
import { SiCoinmarketcap } from 'react-icons/si'; // Importing CoinMarketCap icon
import logo from '../assets/images/swaggyxlogo.png';
import { useTranslation } from 'react-i18next';
import { FaAngleUp } from 'react-icons/fa';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [isLegalOpen, setIsLegalOpen] = useState(false);

  return (
    <footer className="text-white px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-full md:px-24 lg:px-8 mt-10">
      {/* Thin line on top */}
      <div className="border-t border-gray-700 w-full mb-8"></div>
      
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <Link to="/" aria-label="Go home" title="Company" className="inline-flex items-center">
            <img src={logo} className="h-12" alt="Swaggy Bet Logo" />
          </Link>
          <div className="mt-6 lg:max-w-sm">
            {/* Additional content can go here */}
          </div>
        </div>

        <div>
          <span className="text-base font-bold tracking-wide">{t('Join the community')}</span>
          <div className="flex items-center mt-4 space-x-5">
            {/* Social Media Icons */}
            <a
              href="https://www.instagram.com/swaggyx_com/"
              className="text-gray-300 transition-colors duration-300 hover:text-white"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://coinmarketcap.com/community/profile/swaggyxcom/"
              className="text-gray-300 transition-colors duration-300 hover:text-white"
            >
              <SiCoinmarketcap size={24} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61567956449636&mibextid=LQQJ4d"
              className="text-gray-300 transition-colors duration-300 hover:text-white"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://www.tiktok.com/@swaggyx_com?_t=8rDoglUIJ3S&_r=1"
              className="text-gray-300 transition-colors duration-300 hover:text-white"
            >
              <FaTiktok size={24} />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 lg:flex-row">
        <p className="text-sm">
          SWAGGYX CORPORATION S.R.L.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <Link
              to="/about"
              className="text-sm hover:text-gray-300 transition duration-200"
            >
              {t('About Us')}
            </Link>
          </li>
          <li>
            <Link
              to="/privacy"
              className="text-sm hover:text-gray-300 transition duration-200"
            >
              {t('Privacy Policy')}
            </Link>
          </li>
          <li>
            <Link
              to="/tac"
              className="text-sm hover:text-gray-300 transition duration-200"
            >
              {t('Terms & Conditions')}
            </Link>
          </li>
          <li className="relative">
            <button
              onClick={() => setIsLegalOpen(!isLegalOpen)}
              className="text-sm hover:text-gray-300 transition duration-200 inline-flex items-center"
            >
                {t('Legal')}
              <span className="ml-1"><FaAngleUp /></span>
            </button>
            {isLegalOpen && (
              <div className="absolute bottom-full left-0 mb-2 bg-gray-800 rounded-lg shadow-lg py-2 min-w-[150px]">
                <a
                  href="/legal/Legal Opinion - Indonesia.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block px-4 py-2 text-sm hover:bg-gray-700 transition duration-200"
                >
                  Indonesia
                </a>
              </div>
            )}
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
